
let processPostAffiliateRecurly = function (account_code = undefined, plan = undefined) {
    // let $_GET = get_query();
    let callBack = false;
    PostAffTracker.setAccountId('default1');
    try {
        PostAffTracker.track();
        PostAffTracker.executeOnResponseFinished.push(function () { visitorCallBack(); });
    }
    catch (e) { }

    function getVisitorId() {
        return PostAffTracker._getAccountId() + PostAffTracker._cmanager.getVisitorIdOrSaleCookieValue();
    }

    function visitorCallBack() {
        if (callBack == false && "null" != getVisitorId()) {
            callBack = true;
            let pixel = document.createElement("img"); pixel.width = 1; pixel.height = 1;
            pixel.src = 'https://weshape.postaffiliatepro.com/plugins/Recurly/recurly.php?papCookie=' + getVisitorId() + '&account_code=' + account_code + '&plan=' + plan;
            document.body.appendChild(pixel);
        }
    }

    function get_query() {
        let url = location.search;
        let qs = url.substring(url.indexOf('?') + 1).split('&');
        let result = {};
        for (let i = 0; i < qs.length; i++) {
            qs[i] = qs[i].split('=');
            result[qs[i][0]] = decodeURIComponent(qs[i][1]);
        }
        return result;
    }
}